import { useLocation } from '@reach/router';
import { PageProps } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux-store';
import { slugify } from 'utils';

import ArticleListing from './ArticleListing';
import { SEO } from './seo';

interface Props extends PageProps {
  categoryId?: string;
}

const CategoryPage: React.FC<Props> = ({ categoryId, ...rest }) => {
  const { articles, articlesAreChanging } = useSelector(
    (state: ApplicationState) => state.articles,
  );
  const categoryArticles = articles.filter(
    (article) => slugify(article.category) === categoryId,
  );
  const { pathname } = useLocation();

  const categoryName = articles.length ? articles[0].category : '';
  const author =
    Boolean(articles.length) && articles[0].posted_by
      ? articles[0]?.posted_by[0]?.text
      : '';
  const siteUrl = 'https://relay.prototyp.digital';

  return (
    <>
      <SEO
        url={`${siteUrl}${pathname}`}
        description={`All articles from ${categoryName} category`}
        title={`Relay - ${categoryName}`}
        creator={author}
      />

      <ArticleListing
        {...rest}
        articleList={categoryArticles}
        articlesAreChanging={articlesAreChanging}
      />
    </>
  );
};

export default CategoryPage;
