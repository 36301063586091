import { Router } from '@reach/router';
import { SEO, SEOInterceptor } from 'components';
import ArticleListing from 'components/ArticleListing';
import ArticlePage from 'components/ArticlePage';
import CategoryPage from 'components/CategoryPage';
import { PageProps } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux-store';

const IndexPage: React.FC<PageProps> = (props) => {
  const { articles, articlesAreChanging } = useSelector(
    (state: ApplicationState) => state.articles,
  );

  return (
    <>
      <SEOInterceptor />

      <SEO
        creator="@prototyp"
        url="https://relay.prototyp.digital"
        title="Latest"
      />

      <Router>
        <ArticleListing
          {...props}
          path="/"
          articleList={articles}
          articlesAreChanging={articlesAreChanging}
        />
        <CategoryPage {...props} path="/category/:categoryId" />
        <ArticlePage path="/article/:articleId" />
      </Router>
    </>
  );
};

export default IndexPage;
