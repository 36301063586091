import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux-store';
import { articlepage } from 'style';
import * as utils from 'style/utils';
import { slugify } from 'utils';

import { GoogleRichSnippet } from './GoogleRichSnippet';
import { Loading } from './Loading';
import { SEO } from './seo';

interface Props {
  path?: string;
  articleId?: string;
}

const ArticlePage: React.FC<Props> = ({ articleId }) => {
  const { pathname } = useLocation();
  const { articles, articlesAreChanging } = useSelector(
    (state: ApplicationState) => state.articles,
  );
  const foundArticle = articles.find(
    (article) => article._meta.uid === articleId,
  );
  const siteUrl = 'https://relay.prototyp.digital';

  if (articlesAreChanging) {
    return <Loading isLoading />;
  }

  if (!foundArticle) {
    return (
      <div css={utils.wrapper}>
        <article css={articlepage.wrapper}>
          <header css={articlepage.header}>
            Oops! The article you are looking for doesn't exist
          </header>

          <Link to="/" css={articlepage.goback}>
            Back to all articles
          </Link>
        </article>
      </div>
    );
  }

  const {
    _meta,
    title,
    image,
    category,
    posted_by,
    link_to,
    content,
  } = foundArticle;

  return (
    <>
      <SEO
        image={image.url}
        description={RichText.asText(content)}
        url={`${siteUrl}${pathname}`}
        title={title[0].text}
        creator={posted_by ? posted_by[0].text : ''}
      />

      <div css={utils.wrapper}>
        <article css={articlepage.wrapper}>
          <header css={articlepage.header}>
            <div css={articlepage.headerTop}>
              <Link
                css={[
                  articlepage.categoryLink,
                  utils.typeMicro,
                  utils.typeUpperExtended,
                ]}
                to={`/category/${slugify(category)}`}
              >
                <span css={[articlepage.categoryLinkText]}>{category}</span>
              </Link>
              <h1 css={[articlepage.headerTitle, utils.fluidTypeXXXLarge]}>
                {title[0].text}
              </h1>
              <div css={articlepage.tagsWrapper}>
                {_meta.tags.map((tag) => (
                  <span key={tag} css={articlepage.tag}>
                    {tag}
                  </span>
                ))}
              </div>
              <div css={articlepage.headerLink}>
                <a
                  href={link_to.url}
                  rel="noopener noreferrer"
                  css={[utils.btn, utils.btnPrimary]}
                >
                  Visit
                </a>
              </div>
            </div>
          </header>
          <div css={articlepage.visual}>
            <img src={image.url} alt={image.alt} />
          </div>
          <div css={articlepage.content}>
            <RichText render={content} />
          </div>
          <p css={[articlepage.meta, utils.typeMicro]}>
            {posted_by && ` Published by ${posted_by[0].text}`}
          </p>
        </article>
      </div>
      <GoogleRichSnippet
        siteUrl={siteUrl}
        pathname={pathname}
        title={title[0].text}
        image={image.url}
        author={'PROTOTYP'}
        datePublished={_meta.firstPublicationDate}
        dateModified={_meta.lastPublicationDate}
      />
    </>
  );
};

export default ArticlePage;
